/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "picasso-hearing-aid-a-work-of-art",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#picasso-hearing-aid-a-work-of-art",
    "aria-label": "picasso hearing aid a work of art permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Picasso Hearing Aid: A Work of Art"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Losing your hearing is never a good thing, especially if you enjoy listening to music. Finally, there’s a solution for music lovers! It’s called Picasso by Starkey. Imagine being able to customize your musical experiences to your preferences! Don’t skip the concert or sell your piano just because you suffer from hearing loss. You could be the next Beethoven! You don’t have to consider changing your lifestyle. You don’t have to sell your theater tickets. What you do have to do is seriously consider making Picasso the solution to your hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "have-it-your-way",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#have-it-your-way",
    "aria-label": "have it your way permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Have It Your Way"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Customization is the key to satisfaction these days. We have so many options, and we get to choose what colors and textures our furniture is to what landscape we display on our smartphones. The point is, the world is not short on choices, especially when it comes to ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ". With the Picasso, your ability to customize the build of your device is only the beginning, and it makes the choice simple. If you are someone who likes to be discreet about your hearing loss, the IIC (invisible-in-the-canal) style hearing aid can be completely concealed. There are a variety of colors and shapes to suit your needs. Not only is it a superior product, but Starkey was the first company to bring the IIC hearing aid to the market so they have a history of innovation and excellence. You don’t have to compromise when it comes to your hearing. You can CHOOSE to have the best possible experience with the Picasso."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "time-to-accessorize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#time-to-accessorize",
    "aria-label": "time to accessorize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Time to Accessorize"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you think of hearing aids, you probably don’t ", React.createElement(_components.a, {
    href: "/hearing-aids/accessories/",
    className: "c-md-a"
  }, "think about accessories"), ". With the Picasso, you have access to numerous SurfLink products designed to enhance your experience. Say you work in an office with frequent meetings and you’re worried you’ll be forced to retire because you can’t hear well enough to keep up. If you had the SurfLink Remote Microphone, you could set it on the table and crisp, clear sound would be streamed directly to your hearing aid. Never fall behind again! There’s a host of products for any situation! Use the SurfLink media to stream your favorite TV shows and movies. Or, use the small and simple SurfLink Mini Mobile to stream calls from your family and friends right into your ear. Nothing gets lost!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "dont-compromise-your-lifestyle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dont-compromise-your-lifestyle",
    "aria-label": "dont compromise your lifestyle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Don’t Compromise Your Lifestyle"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Picasso is a complete experience. It doesn’t compromise sound and quality when switching between different atmospheres. Going from an outdoor environment to a restaurant and vice versa will transition perfectly. Your hearing aid is personalized to your lifestyle. If you are someone who enjoys going to the theater, you won’t have to change or stay at home because of your hearing loss. Imagine sitting in the stands at your favorite team’s game and being able to hear specific voices in the crowd. You can carry on a conversation without confusion or delay! Living your life with hearing loss has never been more fun and carefree. The Picasso is guaranteed, customized, and optimized to make your experiences flawless."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "give-yourself-and-others-the-gift-of-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#give-yourself-and-others-the-gift-of-hearing",
    "aria-label": "give yourself and others the gift of hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Give Yourself AND OTHERS the Gift Of Hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another reason to love Starkey’s Picasso is the company’s dedication to making life better for all those who suffer from hearing loss. The Starkey Hearing Foundation is active in over 100 countries and they provide over one million hearing health services. Not only is this superior hearing technology available to you but it’s also available to those all over the world who live life in silence. Children all over the world, who otherwise wouldn’t have access to this level of treatment, are being given the opportunity to lead normal lives just like everyone else. It’s a noble cause, and if you choose Picasso, you’re supporting their efforts. It’s a win for you and a win for the world."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-yours-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-yours-today",
    "aria-label": "get yours today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get Yours Today!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All of these amazing features and accessories might be available to you! Don’t worry about the cost because you have resources! Contact us at hear.com to learn more about financing options and to find out exactly how many Picasso options you have to choose from! Are team of experts can help you determine if the Picasso is right for you. You can even sign up for a 45 day risk-free trial and experience the benefits of modern hearing technology! We make the entire process simple and easy for you, so all you need to worry about is enjoying your life."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Starkey Livio now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
